import React from 'react'

const IconLogo = () => (
  <svg
    id='logo'
    xmlns='http://www.w3.org/2000/svg'
    role='img'
    viewBox='0 0 84 96'
  >
    <title>Logo</title>
    <g transform='translate(-8.000000, -2.000000)'>
      <g transform='translate(11.000000, 5.000000)'>
        <path
          d='M 45 47 L 53 61 V 61 L 28.5417 61 L 28.5417 30.3 L 39.2917 30.3 C 45.6917 30.3 49.6917 33.15 49.6917 38.65 C 49.6917 41.95 47.9417 44.35 45 47 Z M 33.5917 43.2 L 39.2417 43.2 C 42.7917 43.2 44.6917 41.85 44.6917 38.95 C 44.6917 36.05 42.7917 34.8 39.2417 34.8 L 33.5917 34.8 L 33.5917 43.2 Z M 33.5917 47.5 L 33.5917 56.5 M 33.6 61 H 46.8 L 39.5 47.5 L 33.5917 47.5 Z'
          fill='currentColor'
        />
        <polygon
          id='Shape'
          stroke='currentColor'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
          points='39 0 0 22 0 67 39 90 78 68 78 23'
        />
      </g>
    </g>
  </svg>
)

export default IconLogo
